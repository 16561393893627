import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Alert, Spin, Row, Col } from 'antd'
import { withIdentity, IdentityProps, isInRole } from 'src/core/services/authentication'
import 'src/core/utils/linq'
import HttpService, { formatMessage } from '../../core/services/http.service'
import { FC, useState } from 'react'

import { container } from '../../inversify.config'
import { QueryResult } from '../../core/stores/data-store'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import logo from 'src/core/assets/logo.png'

interface AdminMenuProps extends RouteComponentProps, WithTranslation, IdentityProps {

}

export interface AppInfo {
  id: string,
  title: string,
  description: string,
  icon: string,
  privateUrl: string,
  publicUrl: string,
  prerelease: boolean,
  hasAccess: boolean,
}

interface AdminMenuState {
  apps: AppInfo[],
  additionalApps: AppInfo[],
  error?: string,
  isBusy: boolean
}

const AppIcon: FC<{ icon?: string, id?: string }> = (props) => {

  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [iconSource64, setIconSource64] = useState(props.icon)
  const inputRef = React.useRef()


  React.useEffect(
    () => {
      if (props.id) {
        setBusy(true)
        httpService.get<QueryResult<AppInfo>>("/api/v1/admin/applications/icon/" + props.id).then(result => {
          setIconSource64(result.data as any)
          setBusy(false)

        }).catch(error => {
          setBusy(false)
        })
      }
    }, [inputRef]
  )

  return !busy ? <img width={48} height={48} src={iconSource64} alt="icon" /> : <LoadingOutlined style={{ fontSize: "48px", color: "white" }} />
}

function getParameterByName(url: string, name: string) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const AdminMenu: FC<AdminMenuProps> = (props) => {

  const [whileRedirect, setwhileRedirect] = React.useState<boolean>(true)
  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [additionalApps, setAdditionalApps] = useState<AppInfo[]>([])
  const [error, setError] = useState<string>(undefined)
  const inputRef = React.useRef()
  const [apps, setApps] = useState<AppInfo[]>([])



  const hasAccessWrapper = (app: AppInfo): boolean => {
    return app.hasAccess && (app.id !=  "1" || isInRole(props.identity, ["Administrator"]))
  }

  React.useEffect(
    () => {
      setBusy(true)
      httpService.get<{ applications: QueryResult<AppInfo>, clientPortalAppId: string }>("/api/v1/admin/applications/homeInfo").then(result => {
        let redirectToClientPortal = false
        let userArea: string = props.identity.profile.area
        if (userArea == 'Customers') {
          redirectToClientPortal = true
        }

        let apps = result.data.applications.items.filter(o => hasAccessWrapper(o))

        let clientPortalApp = apps.firstOrDefault(x => x.id == result.data.clientPortalAppId)
        let identityApp = result.data.applications.items.firstOrDefault(x => x.id == "1")
        if (clientPortalApp && redirectToClientPortal && !hasAccessWrapper(identityApp)) {
          setTimeout(() => {
             window.location.replace(clientPortalApp.privateUrl)
          }, 1000)

          return
        }

        var notPrerealse = apps.filter(x => !x.prerelease);
        if (notPrerealse.length == 1) {
          var app = notPrerealse[0]
          if (app.id != "1") {
            setTimeout(() => {
              window.location.replace(app.privateUrl)
            }, 1000)
            return
          }
        }
        setApps(apps)
        setAdditionalApps(result.data.applications.items.filter(o => !o.hasAccess && o.publicUrl && o.publicUrl != "")),
          setBusy(false)
        setTimeout(() => {
          setwhileRedirect(false)
        }, 1000)


      }).catch(error => {
        setBusy(false)
        setError(error)
      })
    }, [inputRef]
  )






  const { t } = props

  const showPrerelease = getParameterByName(window.location.href, "prerelease")
  if (whileRedirect) {
    return <>
      <div
        style={{
          position: 'fixed',
          height: '100%',
          top: 0,
          left: 0,
          width: '100%',
          background: "rgba(0,0,0,0.7)",
          textAlign: 'center',
          zIndex:999
        }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '100px',
            marginTop: '-50px',
            marginLeft: '-200px',
            width: '400px',
            textAlign: 'center',
          }}
        >

          <div style={{ marginLeft: '55px', marginBottom: '10px', display: 'flex' }}>
            <Spin style={{ marginRight: '10px', marginTop: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
            <img style={{ marginTop: 12, background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px' }} alt="fi-logo" src={logo} height={34} />
            <span style={{ fontSize: 18, float: 'right', marginTop: 12, marginLeft: 5, fontWeight: 'bold', color: 'white' }}>
                        Construire votre futur. Ensemble
            </span>
          </div>

          <strong style={{ marginLeft: '10px', color: 'white' }}>{t('Wait while redirect...')}</strong>
        </div>
      </div>
    </>
  }
  if (apps && apps.length === 0) {
    return <p>{t("It seems that you dont have access to any application. Please contact your administartor")}</p>
  }
  return <>
    <div>
      <div className="tiles wide">
        {error &&
          <Alert
            type='error'
            message={t('An error ocurred')}
            description={error} />
        }
        {apps.map(app => <>
          {!app.prerelease && <a href={app.privateUrl || "#"}>
            < Button className={"menu-item menu-item-release menu-item-access"}>
              <div>
                <AppIcon id={app.id} />
                <h3>{app.title}</h3>
                <p>{t(app.description)}</p>
              </div>
            </Button>
          </a>}
          {app.prerelease && showPrerelease && <a href={app.privateUrl || "#"}>
            <Button className={"menu-item menu-item-prerelease menu-item-access"}>
              <AppIcon id={app.id} />
              <div>
                <h3>{app.title}</h3>
                <p>{t(app.description)}</p>
              </div>
            </Button>
          </a>}
        </>)}
      </div>
      <h4 className="home-header" style={{ marginBottom: "24px" }}>{t("Do you want to access these applications? Ask us!")}</h4>
      <div className={'tiles wide'}>
        {additionalApps.map(app => <>
          {!app.prerelease && <a href={app.publicUrl || app.privateUrl || "#"}>
            <Button className={"menu-item menu-item-no-access"}>
              <div>
                <h3>{app.title}</h3>
                <p>{t(app.description)}</p>
              </div>
            </Button>
          </a>}
          {
            app.prerelease && showPrerelease && <a href={app.publicUrl || app.privateUrl || "#"}> < Button className={"menu-item menu-item-no-access"}>
              <div>
                <h3>{app.title}</h3>
                <p>{t(app.description)}</p>
              </div>
            </Button>
            </a>
          }
        </>)}
      </div>
    </div>
  </>
}
export default withTranslation()(withRouter(withIdentity(AdminMenu)) as any)
